import { httpApi, userApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { 
  IRequestUpdateUserProfile,
  IResponseGetUserInfo,
  IResponseAuthInvite,
  IResponseVerifyInvite,
  IResponseSendInvite,
  IRequestGetUsers,
  IResponseGetUsers,
} from "@app/interfaces/interfaces";

export const updateProfile = async(userID: string, postData: IRequestUpdateUserProfile): Promise<any> => {
  return httpApi.put<any>(`/v3/users/${userID}`, { ...postData })
    .then(({ data }) => data);
};

export const getUserInfo = async (userID: string): Promise<IResponseGetUserInfo> => {
  return userApi.get<IResponseGetUserInfo>('/v3/users', { params: {id: userID} })
    .then(({ data }) => data);
};

export const getUsers = async (data: IRequestGetUsers): Promise<IResponseGetUsers> => {
  return userApi.get<IResponseGetUsers>('/v3/users', { params: {...data} })
    .then(({ data }) => data);
};

export const getUserInfo2 = async (userID: string): Promise<IResponseGetUserInfo> => {
  return userApi.get('/v3/users', {
    params: {id: userID}
  }).then(({ data }) => data);
}

export const sendInvite = async (email: string, inviterEmail: string, inviterName: string): Promise<IResponseAuthInvite | undefined> => {
  return httpApi.post<IResponseAuthInvite>('/Prod/v1/Auth/Invite', {
    mode: 'invite',
    email: email,
    inviter: inviterEmail,
    inviterFullName: inviterName,
  })
  .then(({data}) => data);
}

export const doAcceptInvitation = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  role: string,
  token: string,
): Promise<IResponseAuthInvite | undefined> => {
  return httpApi.post<IResponseAuthInvite>('/Prod/v1/Auth/Invite', {
    mode: 'accept',
    token: token,
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    role: role,
  })
  .then(({data}) => data);
}

export const verifyInvite = async (token: string): Promise<IResponseVerifyInvite | undefined> => {
  return httpApi.post<IResponseVerifyInvite>('/Prod/v1/Auth/Invite', 
    {
      mode: 'verify',
      token: token,
  })
    .then(({data}) => data);
}

export const sendAttorneyInviteV3 = async (email: string, inviterEmail: string, inviterName: string): Promise<IResponseSendInvite | undefined> => {
  return httpApi.post<IResponseSendInvite>('/Prod/v3/invites', {
    email: email,
    inviterEmail: inviterEmail,
    inviterFullName: inviterName,
  })
  .then(({data}) => data);
}

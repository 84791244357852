import { BellOutlined, DollarOutlined, SecurityScanOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';

interface ProfileMenuItem {
  path: string;
  title: string;
  index: string;
}

interface IProfileMenus {
  [key: string]: ProfileMenuItem[];
}

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  href: string;
}

interface IProfileNav {
  [key: string]: ProfileNavItem[];
}

export const profileNavData: IProfileNav = {
  "normal": [
    {
      id: 1,
      name: 'Profile',
      icon: <UserOutlined />,
      color: 'success',
      href: '/n/profile',
    },
    {
      id: 2,
      name: 'Attorneys',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/n/attorneys',
    },
    {
      id: 3,
      name: 'My IP Portfolio',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/n/certifications',
    },
    {
      id: 4,
      name: 'Payments',
      icon: <BellOutlined />,
      color: 'success',
      href: '/n/payments',
    },
    {
      id: 5,
      name: 'Messages',
      icon: <BellOutlined />,
      color: 'success',
      href: '/n/messages',
    },
  ],
  "attorney": [
    {
      id: 1,
      name: 'Profile',
      icon: <UserOutlined />,
      color: 'success',
      href: '/att/profile',
    },
    {
      id: 5,
      name: 'Audit Trails',
      icon: <BellOutlined />,
      color: 'success',
      href: '/att/audit_trails',
    },
    {
      id: 6,
      name: 'My IP Portfolio',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/att/certifications',
    },
    {
      id: 3,
      name: 'Law Firm',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/att/law_firm',
    },
    {
      id: 4,
      name: 'Clients',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/att/clients',
    },
    {
      id: 8,
      name: 'Payments',
      icon: <DollarOutlined />,
      color: 'success',
      href: '/att/payments',
    },
    {
      id: 9,
      name: 'Messages',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/att/messages',
    },
  ],
  "law_firm": [
    {
      id: 1,
      name: 'Profile',
      icon: <UserOutlined />,
      color: 'success',
      href: '/lf/profile',
    },
    {
      id: 2,
      name: 'Law firm',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/lf/law-firm-info',
    },
    {
      id: 3,
      name: 'Attorneys',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/lf/attorneys',
    },
    {
      id: 4,
      name: 'Audit Trails',
      icon: <BellOutlined />,
      color: 'success',
      href: '/lf/audit_trails',
    },
    {
      id: 5,
      name: 'IP Portfolio',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/lf/certifications',
    },
    {
      id: 6,
      name: 'Payments',
      icon: <DollarOutlined />,
      color: 'success',
      href: '/lf/payments',
    },
    {
      id: 8,
      name: 'Messages',
      icon: <SecurityScanOutlined />,
      color: 'success',
      href: '/lf/messages',
    },
  ],
  "admin": [
    // {
    //   id: 1,
    //   name: 'Law firms',
    //   icon: <UserOutlined />,
    //   color: 'success',
    //   href: '/admin/law_firms'
    // },
    // {
    //   id: 2,
    //   name: 'Attorneys',
    //   icon: <SecurityScanOutlined />,
    //   color: 'success',
    //   href: '/admin/attorneys',
    // },
    // {
    //   id: 3,
    //   name: 'IP Portfolios',
    //   icon: <SecurityScanOutlined />,
    //   color: 'success',
    //   href: '/admin/certifications',
    // },
    // {
    //   id: 4,
    //   name: 'Audit Trails',
    //   icon: <BellOutlined />,
    //   color: 'success',
    //   href: '/admin/audit-trails',
    // },
    // {
    //   id: 5,
    //   name: 'Payments',
    //   icon: <SecurityScanOutlined />,
    //   color: 'success',
    //   href: '/admin/payments',
    // },
    // {
    //   id: 6,
    //   name: 'Statistics',
    //   icon: <SecurityScanOutlined />,
    //   color: 'success',
    //   href: '/admin/statistics',
    // },
    {
      id: 7,
      name: 'Messages',
      icon: <DollarOutlined />,
      color: 'success',
      href: '/admin/messages',
    },
  ],
};


export const menus:IProfileMenus = {
  'normal': [
    {
      path: '/n/profile',
      title: 'Profile',
      index: 'profile',
    },
    {
      path: '/n/attorneys',
      title: 'Attorneys',
      index: 'client_attorneys',
    },
    {
      path: '/n/certifications',
      title: 'My IP Portfolio',
      index: 'certifications',
    },
    {
      path: '/n/payments',
      title: 'Payments',
      index: 'payments',
    },
  ],
  'attorney': [
    {
      path: '/att/profile',
      title: 'Profile',
      index: 'profile',
    },
    {
      path: '/att/audit_trails',
      title: 'Audit Trails',
      index: 'audit_trails',
    },
    {
      path: '/att/certifications',
      title: 'My IP Portfolio',
      index: 'copyrights',
    },
    {
      path: '/att/law_firm',
      title: 'Law firm',
      index: 'my_law_firm',
    },
    {
      path: '/att/clients',
      title: 'Clients',
      index: 'clients',
    },
    {
      path: '/att/payments',
      title: 'Payments',
      index: 'payments',
    },
  ],
  'law_firm': [
    {
      path: '/lf/profile',
      title: 'Profile',
      index: 'profile',
    },
    {
      path: '/lf/law-firm-info',
      title: 'Law firm',
      index: 'law_firm_profile',
    },
    {
      path: '/lf/attorneys',
      title: 'Attorneys',
      index: 'law_firm_attorneys',
    },
    {
      path: '/lf/audit_trails',
      title: 'Audit Trails',
      index: 'audit_trails',
    },
    {
      path: '/lf/certifications',
      title: 'IP Portfolio',
      index: 'copyrights',
    },
    {
      path: '/lf/payments',
      title: 'Payments',
      index: 'payments',
    },
  ],
  'admin': [
    {
      path: '/admin/messages',
      title: 'Messages',
      index: 'admin_messages',
    },
    // {
    //   path: '/admin/users',
    //   title: 'Users',
    //   index: 'admin_users',
    // },
    // {
    //   path: '/admin/law_firms',
    //   title: 'Law firms',
    //   index: 'admin_lawfirms',
    // },
    // {
    //   path: '/admin/attorneys',
    //   title: 'Attorneys',
    //   index: 'admin_attorneys',
    // },
    // {
    //   path: '/admin/certifications',
    //   title: 'IP Portfolios',
    //   index: 'admin_copyrights',
    // },
    // {
    //   path: '/admin/audit_tails',
    //   title: 'Audit trails',
    //   index: 'admin_audit_trails',
    // },
    // {
    //   path: '/admin/payments',
    //   title: 'Payments',
    //   index: 'admin_payments',
    // },
    // {
    //   path: '/admin/statistics',
    //   title: 'Statistics',
    //   index: 'admin_statistics',
    // },
  ],
}
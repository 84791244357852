import { NumericLiteral } from 'typescript';
import moment, { Moment } from 'moment';
import {
  IUserProfile, 
  IUserProfileModel,
  ILawfirmData,
} from '@app/domain/UserModel';

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = 'de' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  id: string;
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  zip: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}

export type IResPaginationData = {
  total: number;
  offset: number,
  limit: number,
}

export type ITypeUserPricingPlan = "free"|"payed"|"enterprise"|null;

export type ITypeAttorneyClientRelationStatus = "invite"|"accept"|"reject"|"deleted"|null;

export type ITypeLawfirmAdminStatus = "invite"|"request"|"accept"|"suspend"|"deleted"|null;

// export interface SearchData {
//   keyword: string;
//   type?: string;    // "search" or "fetch"
//   userID?: string;
//   offset?: number;
//   limit?: number;
// };



export interface IRequestCreateDerivative {
  certID: string;
  userName: string;
  userEmail: string;
  meta: string;
  mType: string;  // record, ai_gen, local
  storePath: string;
  mediaOriginalName: string;
  mediaOriginalExt: string;
  mediaSize: number;
}

export interface IRequestGetDerivatives {
  itemID?: string;
  certID?: string;
  keyword?: string;
  userID?: string;
  type?: string;
  offset?: number;
  limit?: number;
}

export interface IRequestUpdateUserProfile {
  updateMode: "pricing_plan"|"profile";
  firstName?: string;
  lastName?: string;
  zipCode?: string;
  publicEmail?: string;
  publicPhonenumber?: string;
  addrStreet?: string;
  addrCity?: string;
  addrState?: string;
  status?: string;
  plan?: ITypeUserPricingPlan;
}

export interface CertformData {
  dataType?: string;
  natureOfWork?: string;
  workDescription?: string;
  creationDate?: moment.Moment;
  author?: string;
  ownershipDetails?: string;
  publicationStatus?: string;
  priorVersions?: string;
  thirdPartyContent?: string;
  intendedUse?: string;
  registrationObjectives?: string;
  digitalContent?: string;
  externalAgreement?: string;
};

export interface CertformData2 {
  dataType?: string;
  titleOfWork?: string;
  completionDate?: moment.Moment;
  publicationStatus?: string;
  firstPublishedInfo?: string;
  authorName?: string;
  authorBirth?: moment.Moment;
  authorCreateAim?: string;
  copyrightOwnerName?: string;
  copyrightOwnerFullName?: string;
  copyrightOwnerContactInfo?: string;
  previousRegistration?: string;
  previousRegistrationInfo?: string;
  isNewMaterial?: string;
  natureOfMaterial?: string;
  registererFullName?: string;
  registererRelationship?: string;
  signature?: string;
  dateOfSubmission?: moment.Moment;
  confirmInfo?: boolean;
  confirmInfo2?: boolean;
  acceptTermsOfService?: boolean;
};


export interface IRequestCreateCopyright {
  cert?: CertformData2;
  prevID?: string;
  prompt?: string;
  userEmail?: string;
}

export interface ChatGPTResponseChoicesFormat {
  message?: {
    content?: string;
  }
}
export interface ChatGPTResponseFormat {
  choices?: ChatGPTResponseChoicesFormat[];
  error?: {message: string};
}
export interface IResponseCreateCopyright {
  recordID: string;
  certID: string;
  aiResponse: ChatGPTResponseFormat;
}

export interface ISourceData {
  certID: string,        // certification ID
  itemID: string,        // source item ID
  mType: string,         // media type: 'audio', 'image', 'video', 'other
  originalName: string, // Source file's original Name
  sourcePath: string,    // Source file uploaded Path
  sourceData?: string,    // Source file uploaded Path
  sType: string,         // Source type : 'internal'-uploaded file, 'external' - link supported file
  userID: string,        // user id
}

export interface ICertificationData {
  cert_id: string,
  user_id: string,
  user_email: string,
  data_type: string,
  title_of_work: string,
  publication_status: string,
  first_published_info: string,
  author_name: string,
  author_birth: string,
  author_create_aim: string,
  copyright_owner_name: string,
  copyright_owner_fullname: string,
  copyright_owner_contact_info: string,
  previous_registration: string,
  previous_registration_info: string,
  is_new_material: string,
  registarer_full_name: string,
  registerer_relationship: string,
  signature: string,
  date_of_submission: string,
  chat_gpt: string;
  status: string;
  disput_status: string;
  created_at: string;
  source?: ISourceData | null,
}

export interface IRequestGetCertifications {
  keyword: string;
  type?: string;    // "search" or "fetch"
  userID?: string;
  offset?: number;
  limit?: number;
};

export interface IResponseGetCertifications {
  data?: ICertificationData[];
  pagination: IResPaginationData;
}

export interface IResponseGetCertificationInfo {
  status: string;
  message?: string;
  certification?: ICertificationData;
}

export type GitDataChange = {
  fileName: string;
  content: string;
}

export type SourceGitData = {
  type: 'git';
  key: string;
  hash: string;
  author: string;
  date: string;
  message: string;
  modifiedFiles: string[];
  modifiedContents: GitDataChange[];
}

export interface IDerivativeData {
  id: string,
  cert_id: string,
  media_type: string,
  media_meta_data: string,
  storage_type: string,
  store_path: string,
  media_original_name: string,
  media_size: number,
  user_id: string,
  user_email: string,
  user_name: string,
  created_at: string,
}

export interface IResponseGetDerivatives {
  data: IDerivativeData[];
  pagination: IResPaginationData;
}

export interface IRequestCreateDerivative {
  certID: string;
  userName: string;
  userEmail: string;
  meta: string;
  mType: string;  // record, ai_gen, local
  storePath: string;
  mediaOriginalName: string;
  mediaOriginalExt: string;
  mediaSize: number;
}

// ----------------------------------------------------------------------------------------
// -------------------------------- Audit Trail -------------------------------------------

export interface IRequestGetAuditTrails {
  itemID?: string;
  certID?: string;
  keyword?: string;
  userID?: string;
  type?: string;
  offset?: number;
  limit?: number;
}

export type IAuditTrailsPurchaseInfo = {
  id: string;
  purchase_id: string;
  purchase_amount: number;
  created_at: string;
};

export type IAuditTrailsRow = {
  id: string;
  user_id: string;
  model: string;
  prompt: string;
  response: string;
  origin: string;
  download_count: string;
  purchase: IAuditTrailsPurchaseInfo | null;
  created_at: string;
}

export interface IResponseAuditTrails {
  data: IAuditTrailsRow[];
  pagination: IResPaginationData;
}

// ----------------------------------------------------------------------------------------
//------------------------------------- users ----------------------------------------------

export interface IResponseGetUserInfo extends IUserProfileModel {
  message?: string;
}

export interface IResponseAuthInvite {
  success: boolean,
  inviteID: string,
  sesResponse: string,
  message?: '',
}

export interface IResponseVerifyInvite {
  success: boolean,
  message?: string,
  email: string,
}

export interface IResponseSendInvite {
  message: string;
  id: string;
}

export interface IRequestGetUsers {
  keyword: string;
  status?: string;
  role?: string;
  offset: number;
  limit: number;
}

export interface IResponseGetUsers {
  message?: string;
  data: IUserProfile[],
  pagination: IResPaginationData;
}

// ----------------------------------------------------------------------------------------
// --------------------------- Payments ---------------------------------------------------

export interface IPaymentDataV3 {
  id: string;
  gateway: string;
  item_type: string;
  user_id: string;
  email: string;
  payment_id: string;
  payment_order_id: string;
  payment_status: string;
  amount: string;
  sign_data: string;
  sign_key: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  item_id: string;
}

export interface IRequestGetPayments {
  keyword: string;
  offset: number;
  limit: number;
  startDate: string;
  endDate: string;
}

export interface IResponseGetPayments {
  message: string;
  data: IPaymentDataV3[],
  pagination: IResPaginationData;
}

export interface ISquarePaymentResult {
  id: string;
  status: string;
  receiptUrl: string;
  orderId: string;
}

export interface IRequestPayment {
  email?: string;
  username?: string;
  purchaseMode: string;    // square, stripe etc
  purchaseItem: string;    // copyright, audit
  purchaseItemID: string;  // copyright_id or audit_id
  purchaseOwner: string;   // attorney, lawfirm
  lawfirmID?: string;
  // squareup payment variables
  locationId?: string;
  sourceId?: string|undefined;
  verificationToken?: string|undefined;
  customerId?: string|undefined;
  idempotencyKey?: string;
}

export interface IResponsePayment {
  success: boolean,
  payment: ISquarePaymentResult,
  db: string,
  sesResponse: string,
}

export interface IPaymentCardRow {
  id: string;
  user_id: string;
  card_number: string;
  card_holder: string;
  card_exp: string;
  card_cvv: string;
  card_zip: string;
  created_at: string;
  status: string;
  verified: string;
}

export interface IResponseGetPaymentCards {
  message: string;
  data: IPaymentCardRow[];
}

export interface IResponseAddPaymentCard {
  message: string;
  data: IPaymentCardRow;
}

//--------------------------------------------------------------------------------------------
//--------------------------------- messages -------------------------------------------------

export interface IMessageDataV3 {
  id: string;
  msg_type: string;
  sender: string;
  receiver: string;
  msg_content: string;
  has_attachment: boolean;
  status: string;
  checked_at: string;
  created_at: string;
  updated_at: string;
  sender_deleted_at: string;
  receiver_deleted_at: string;
  msg_title: string;
  sender_email: string;
  sender_phonenumber: string;
  sender_name: string;
}

export interface IRequestSendMessage {
  msgType: string;
  receiver: string;
  msgTitle: string;
  msgContent: string;
  status: string;
}

export interface IResponseSendMessage {
  message: string;
  id: string;
}

export interface IRequestGetMessages {
  keyword: string;
  mode: string; // "sent"|"received"
  status: string;
  offset: number;
  limit: number;
  startDate: string;
  endDate: string;
}

export interface IResponseGetMessages {
  message: string;
  data: IMessageDataV3[],
  pagination: IResPaginationData;
}


//--------------------------------------------------------------------------------------------
//---------------------------------- lawfirm -------------------------------------------------

export interface IRequestCreateLawfirm {
  orgName: string;
  abbr: string;
  orgType: string;
  contactEmail: string;
  contactPhonenumber: string;
  addrStreet: string;
  addrCity: string;
  addrState: string;
};

export interface IResponseCreateLawfirm {
  message: string;
  data: ILawfirmData;
};

export interface IRequestUpdateLawfirm {
  orgName: string;
  abbr: string;
  orgType: string;
  contactEmail: string;
  contactPhonenumber: string;
  addrStreet: string;
  addrCity: string;
  addrState: string;
};


export interface IAttorneyInfoDataV3 {
  id: string;
  user_id: string;
  law_firm_id: string;
  title: string;
  status: string;
  actor: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  status_changed_at: string;
  email: string;
  public_email: string;
  public_phonenumber: string;
  user_name: string;
  address: string;
  avatar: string;
  org_name: string;
  abbr: string;
  org_type: string;
}

export interface IRequestGetAttorneys {
  lawfirmID?: string;
  keyword?: string;
  userID?: string;
  status?: string;
  offset?: number;
  limit?: number;
}

export interface IResponseGetAttorneys {
  message: string;
  data: IAttorneyInfoDataV3[];
  pagination: IResPaginationData;
}

export interface IRequestGetLawfirms {
  itemID?: string;
  certID?: string;
  keyword?: string;
  userID?: string;
  type?: string;
  offset?: number;
  limit?: number;
}

export interface ILawfirmRow {
  id: string;
  org_name: string;
  abbr: string;
  org_type: string;
  logo: string;
  atterney_cnt: string;
  admin_cnt: string;
  contact_email: string;
  contact_phone_number: string;
  address: string;
  addr_street: string;
  addr_city: string;
  addr_state: string;
  default_credit_card: string;
  billing_address: string;
  sign_data: string;
  sign_key: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IResponseGetLawfirms {
  message: string;
  data: ILawfirmRow[];
  pagination: IResPaginationData;
}

//--------------------------------------------------------------------------------------------
//---------------------------------- Attorney -------------------------------------------------

export interface IRequestAttorneyGetClients {
  keyword: string;
  status?: string;
  offset: number;
  limit: number;
}

export interface IResponseAttorenyGetClients {
  message?: string;
  data: IClientData[],
  pagination: IResPaginationData,
}

export interface IClientData {
  id: string;
  user_id: string;
  attorney_id: string;
  status_user: string;
  status_attorney: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  user_name: string;
  email: string;
  public_email: string;
  public_phonenumber: string;
  addr_street: string;
  addr_city: string;
  addr_state: string;
  user_job: string;
}

export interface IAttorneyClientDataV3 {
  id: string;
  user_id: string;
  attorney_id: string;
  status_user: ITypeAttorneyClientRelationStatus;
  status_attorney: ITypeAttorneyClientRelationStatus;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  user_name: string;
  email: string;
  public_email: string;
  public_phonenumber: string;
  addr_street: string;
  addr_city: string;
  addr_state: string;
  user_job: string;
  org_name: string;
  title: string;
}

// export interface UserAttorny {
//   id: string;
//   user_id: string;
//   attorney_id: string;
//   status_user: string;
//   status_attorney: string;
//   created_at: string;
//   updated_at: string;
//   deleted_at: string;
//   user_name: string;
//   public_email: string;
//   public_phonenumber: string;
//   addr_street: string;
//   addr_city: string;
//   addr_state: string;
//   user_job: string;
// }

export interface IRequestUserGetAttorneys {
  keyword: string;
  status?: string;
  offset: number;
  limit: number;
}

export interface IResponseUserGetAttorneys {
  message: string;
  data: IAttorneyClientDataV3[],
  pagination: IResPaginationData,
}

//--------------------------------------------------------------------------------------------
//---------------------------------- Tickets -------------------------------------------------

export interface ITicketDataRow {
  id: string;
  ticket_type: string;
  ticket_num: string;
  sender: string;
  receiver: string;
  msg_title: string;
  msg_content: string;
  sender_unread_msg_count: number;
  receiver_unread_msg_count: number;
  total_msg_count: number;
  has_attachment: boolean;
  status: string;
  closed_at: string;
  created_at: string;
  updated_at: string;
  sender_deleted_at: string;
  receiver_deleted_at: string;
  //user info
  email: string;
  public_phonenumber: string;
  user_name: string;
  address: string;
  messages?: ITicketMessageDataRow[];
};

export interface ITicketMessageDataRow {
  id: string;
  ticket_id: string;
  sender: string;
  msg_content: string;
  has_attachment: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IRequestGetTickets {
  keyword: string;
  status?: string;
  offset: number;
  limit: number;
};

export interface IResponseGetTickets {
  message: string;
  data: ITicketDataRow[];
  pagination: IResPaginationData,
};

export interface IResponseGetTickeDetail {
  message: string;
  data: ITicketDataRow;
};

export type ITypeTicketStatus = "init" | "open" | "close";

export interface IRequestCreateTicket {
  type: string;
  title: string;
  content: string;
  status: ITypeTicketStatus; // init | open | close
};

export interface IResponseSendTicketMessage {
  message: string;
  data: ITicketMessageDataRow; // init | open | close
};

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/inputs/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);

  const [searchParams] = useSearchParams();

  const [form] = BaseForm.useForm();
  const email = BaseForm.useWatch('email', form);

  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  const user = searchParams.get('user');

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ email: window.atob(user) });
    }
  }, [form, user]);

  useEffect(() => {
    if (securityCode.length === 6) {
      const emailValue = form.getFieldValue('email');
      setLoading(true);
      dispatch(doVerifySecurityCode({ email: emailValue, code: securityCode }))
        .unwrap()
        .then(onFinish || navigateForward)
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [securityCode, navigateForward, onFinish, dispatch]);

  return (
    <Auth.FormWrapper>
      <BaseForm form={form} layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          {/*{isLoading ? <BaseSpin /> : (*/}
          {/*  <>*/}
            <Auth.FormItem
              name="email"
              label={t('common.email')}
              rules={[
                { required: true, message: t('common.requiredField') },
                {
                  type: 'email',
                  message: t('common.notValidEmail'),
                },
              ]}
            >
              <Auth.FormInput placeholder={t('common.email')} />
            </Auth.FormItem>
            <VerificationCodeInput autoFocus onChange={setSecurityCode} />
          {/*  </>*/}
          {/*)}*/}
          {/*<Link to="/" target="_blank">*/}
          {/*  <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>*/}
          {/*</Link>*/}
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};

import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { useAppDispatch } from "@app/hooks/reduxHooks";
import { loadUserInfo } from "@app/store/slices/userSlice";
import { Loading } from '@app/components/common/Loading/Loading';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  // const token = useAppSelector((state) => state.auth.token);
  // const user = useAppSelector((state) => state.user.user);
  // const dispatch = useAppDispatch();

  // const [isVerifying, setIsVerifying] = useState<boolean>(true);

  // useEffect(() => {
  //   if (token) {
  //     dispatch(loadUserInfo(user?.id || ""))
  //       .unwrap()
  //       .then((token) => {
  //         setIsVerifying(false);
  //       })
  //       .catch((err) => {
  //         setIsVerifying(false);
  //       });
  //   }
  // }, [token])

  // return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
  return (
    <>
      {children}
    </>
  );
};

export default RequireAuth;

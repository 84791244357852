import React from 'react';
import { SecurityCodeForm } from '@app/components/auth/SecurityCodeForm/SecurityCodeForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useNavigate } from "react-router-dom";
import { notificationController } from "@app/controllers/notificationController";

const SecurityCodePage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.securityCode')}</PageTitle>
      <SecurityCodeForm onFinish={() => {
        notificationController.success({ message: "You've successfully verified your account." });
        navigate('/auth/login');
      }} />
    </>
  );
};

export default SecurityCodePage;

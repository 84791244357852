import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row, Col, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { ReactComponent as GoogleIcon } from '@app/assets/icons/google.svg';
import { ReactComponent as FacebookIcon } from '@app/assets/icons/facebook.svg';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { BaseButton, LinkButton } from "@app/components/common/BaseButton/BaseButton";
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

interface UserRole {
  value: string;
  label: string;
}

const initValues = {
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: true,
};

const userRoles:UserRole[] = [{
  value: 'normal',
  label: 'I am a creator who wants IP protection.',
}, {
  value: 'attorney',
  label: 'I am an individual attorney.',
}, {
  value: 'law_firm',
  label: 'I am a law firm organization.',
}]

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    console.log('values:', values);
    setLoading(true);
    dispatch(doSignUp({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      role: values.role,
    }))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        navigate('/auth/security-code?user=' + window.btoa(values.email));
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Row>
          <Col span={5}>
            <img src="https://basicannon.s3.amazonaws.com/blinder+Main+Logo.png" width="100%" alt="Blinder Logo" className="logo" />
          </Col>
          <Col span={14}>
            <S.Title>{t('common.signUp')}</S.Title>
          </Col>
          <Col span={5}></Col>
        </Row>
        <Auth.FormItem
          name="role"
          label="Choose your role."
          rules={[
            { required: true, message: t('common.requiredField') },
          ]}
        >
          <Select
            allowClear
            options={userRoles}
            placeholder="Choose your role."
          />
        </Auth.FormItem>
        <Row gutter={8}>
          <Col span={12}>
            <Auth.FormItem
              name="firstName"
              label={t('common.firstName')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput placeholder={t('common.firstName')} />
            </Auth.FormItem>
          </Col>
          <Col span={12}>
            <Auth.FormItem
              name="lastName"
              label={t('common.lastName')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInput placeholder={t('common.lastName')} />
            </Auth.FormItem>
          </Col>
        </Row>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Row gutter={8}>
          <Col span={12}>
            <Auth.FormItem
              label={t('common.password')}
              name="password"
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <Auth.FormInputPassword placeholder={t('common.password')} />
            </Auth.FormItem>
          </Col>
          <Col span={12}>
            <Auth.FormItem
              label={t('common.confirmPassword')}
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: t('common.requiredField') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('common.confirmPasswordError')));
                  },
                }),
              ]}
            >
              <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
            </Auth.FormItem>
          </Col>
        </Row>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <BaseCheckbox>
              <Auth.Text>
                {t('signup.agree')}{' '}
                <LinkButton href="/terms_conditions" target={'_blank'}>
                  {t('blinder.text.termsOfService')}
                </LinkButton>{' '}
                and{' '}
                <LinkButton href="https://clerkbridge.com/privacy-policy/" target={'_blank'}>
                  {t('blinder.text.privacyPolicy')}
                </LinkButton>
              </Auth.Text>
            </BaseCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.signUp')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Link to="/auth/login">
            <Auth.SubmitButton type="default" htmlType="button">
              {t('common.login')}
            </Auth.SubmitButton>
          </Link>
        </BaseForm.Item>
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <GoogleIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('signup.googleLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <FacebookIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('signup.facebookLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/* <Auth.FooterWrapper>
          <Auth.Text>
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper> */}
      </BaseForm>
    </Auth.FormWrapper>
  );
};

import { createAction, createAsyncThunk, createSlice, PrepareAction, PayloadAction } from '@reduxjs/toolkit';
import { UserModel, IUserProfileModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { RootState } from '../store';

import {
  getUserInfo2
} from "@app/api/blinder.user.api";

export interface UserState {
  user: UserModel | null;
}

const initialState: UserState = {
  user: readUser(),
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const setUserProfile = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

export const loadUserInfo = createAsyncThunk('user/loadInfo', async (userID: string, { getState }) => 
  getUserInfo2(userID).then((res) => {
    const state: RootState = getState() as RootState; 
    // if (state.user.user) {
    //   persistUser({
    //     ...state.user.user,
    //     profile: (res.user as unknown) as IUserProfileModel,
    //   });
    // }
    return res;
  })
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateProfile(state, action: PayloadAction<Partial<IUserProfileModel['user']>>) {
      if (state.user) {
        state.user.profile.user = {
          ...state.user.profile.user,
          ...action.payload,
        };
        persistUser(state.user);
      }
    },
    updateAttorney(state, action: PayloadAction<Partial<IUserProfileModel['attorney']>>) {
      if (state.user) {
        state.user.profile.attorney = {
          ...state.user.profile.attorney,
          ...action.payload,
        };
        persistUser(state.user);
      }
    },
    updateLawfirm(state, action: PayloadAction<Partial<IUserProfileModel['lawfirm']>>) {
      if (state.user) {
        state.user.profile.lawfirm = {
          ...state.user.profile.lawfirm,
          ...action.payload,
        };
        persistUser(state.user);
      }
    },
    updateLawfirmAdmin(state, action: PayloadAction<Partial<IUserProfileModel['lawfirmAdmin']>>) {
      if (state.user) {
        state.user.profile.lawfirmAdmin = {
          ...state.user.profile.lawfirmAdmin,
          ...action.payload,
        };
        persistUser(state.user);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUser, (state, action) => {
        state.user = action.payload;
      })
      .addCase(loadUserInfo.fulfilled, (state, action) => {
        console.log('', action.payload);
        if (state.user)
          state.user.profile = (action.payload as unknown) as IUserProfileModel;
      });
  },
});

export const { updateProfile, updateAttorney, updateLawfirm, updateLawfirmAdmin } = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ProfileInfo } from '@app/components/profile/profileCard/ProfileInfo/ProfileInfo';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ProfileNav } from '@app/components/profile/profileCard/ProfileNav/ProfileNav';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { UserRole } from '@app/domain/UserModel';

const ProfileLayout: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.pathname === '/n') {
  //     navigate('/n/profile');
  //   }
  // }, [location.pathname, navigate]);

  useEffect(() => {
    if (user?.role !== 'normal') {
      navigate('/home');
    }
  }, [user]);

  return (
    <>
      <PageTitle>User page</PageTitle>

      <BaseRow gutter={[30, 30]} style={{marginTop: "20px"}}>
        <BaseCol xs={24} md={24} xl={6}>
          <ProfileCard>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={24} md={12} xl={24}>
                <ProfileInfo profileData={user} />
              </BaseCol>

              <BaseCol xs={24} md={12} xl={24}>
                <ProfileNav />
              </BaseCol>
            </BaseRow>
          </ProfileCard>
        </BaseCol>
        <BaseCol xs={24} md={24} xl={18}>
          <Outlet />
        </BaseCol>
      </BaseRow>
    </>
  );
};

const ProfileCard = styled(BaseCard)`
  height: unset;
`;

const Btn = styled(BaseButton)`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export default ProfileLayout;

import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Row, Col, Space } from 'antd';
import { useTranslation } from "react-i18next";
import { BaseForm } from "@app/components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "@app/hooks/reduxHooks";
import { doLogin } from "@app/store/slices/authSlice";
import { config } from "@app/config";

import { notificationController } from "@app/controllers/notificationController";
import { ReactComponent as BlinderIcon } from "@app/assets/icons/btc.svg";
import * as S from "./LoginForm.styles";
import * as Auth from "@app/components/layouts/AuthLayout/AuthLayout.styles";

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

function isHttpValid(str:string) {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
 }

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then((res) => {
        const redirecURL = searchParams.get('redirect_url');
        if (redirecURL && isHttpValid(redirecURL)) {
          window.location.href = redirecURL + '?authKey=' + res.token;
        } else {
          if (!res?.user?.profile?.user?.pricing_plan && (res?.user.role === 'attorney' || res?.user.role === 'law_firm')) {
            navigate('/pricing-plan');
          } else {
            navigate('/home');
          }
        }
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const doBlinderAuth = () => {
    const clientId = config.clientId;
    const redirectUri = config.redirectUri;
    const cognitoDomain = config.cognitoDomain;
    const scope = 'email+openid+profile';
    window.location.href = `https://${cognitoDomain}/login?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  }

  const gotoDashboard = () => {
    navigate('/certs',{ replace: true });
  }

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Row>
          <Col span={5}>
            <img src="https://basicannon.s3.amazonaws.com/blinder+Main+Logo.png" width="100%" alt="Blinder Logo" className="logo" />
          </Col>
          <Col span={14}>
            <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
          </Col>
          <Col span={5}></Col>
        </Row>
        {/* <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription> */}
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item >
          <Auth.SubmitButton type="default" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        {/* <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="button" onClick={doBlinderAuth}>
            Login with Blinder AI
          </Auth.SocialButton>
        </BaseForm.Item> */}
        <BaseForm.Item >
          <Auth.SubmitButton type="default" htmlType="button" onClick={gotoDashboard}>
            {t('blinder.buttons.guest')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <Link to="/auth/sign-up">
            <Auth.SubmitButton type="primary" htmlType="button">
              {t('common.signUp')}
            </Auth.SubmitButton>
          </Link>
        </BaseForm.Item>
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <GoogleIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('login.googleLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/*<BaseForm.Item noStyle>*/}
        {/*  <Auth.SocialButton type="default" htmlType="submit">*/}
        {/*    <Auth.SocialIconWrapper>*/}
        {/*      <FacebookIcon />*/}
        {/*    </Auth.SocialIconWrapper>*/}
        {/*    {t('login.facebookLink')}*/}
        {/*  </Auth.SocialButton>*/}
        {/*</BaseForm.Item>*/}
        {/* <Auth.FooterWrapper>
          <Auth.Text>
            {t('login.noAccount')}{' '}
            <Link to="/auth/sign-up">
              <Auth.LinkText>{t('common.here')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper> */}
      </BaseForm>
    </Auth.FormWrapper>
  );
};

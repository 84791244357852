import { UserModel } from '@app/domain/UserModel';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || 'bearerToken';
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  let userJson;
  try {
    userJson = userStr ? JSON.parse(userStr):null;
    if (userJson !== null) {
      if (userJson.exp < (new Date()).getTime() / 1000) {
        deleteToken();
        deleteUser();
        userJson = null;
      }
    }
  } catch (e) {
    deleteToken();
    deleteUser();
    userJson = null;
  }

  return userJson;
};

export const persistRefreshToken = (token: string) => {
  localStorage.setItem('refreshToken', token);
};

export const readRefreshToken = (): string => {
  return localStorage.getItem('refreshToken') || 'bearerToken';
};

export const persistIDToken = (token: string): void => {
  localStorage.setItem('idToken', token);
};

export const readIDToken = (): string => {
  return localStorage.getItem('idToken') || 'bearerToken';
};

export const deleteToken = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
};
export const deleteUser = (): void => localStorage.removeItem('user');

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { menus } from '@app/constants/profileNavData';

import * as S from './ProfileOverlay.styles';


interface ProfileOverlayProps extends React.HTMLAttributes<HTMLElement> {
  onClose: () => void;
}


export const ProfileOverlay: React.FC<ProfileOverlayProps> = ({ onClose, ...props }: ProfileOverlayProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const [userRole, setUserRole] = useState('normal');


  useEffect(() => {
    if (user?.role) {
      if (menus[user?.role]){
        setUserRole(user.role);
      } else {
        setUserRole('normal');
      }
    }
  }, [user]);

  return (
    <div {...props}>
      {menus[userRole].map((page, index) => (
        <S.Text key={page.index}>
          <Link onClick={onClose} to={page.path}>{page.title}</Link>
        </S.Text>
      ))}
      <S.ItemsDivider />
      <S.Text>
        <Link to="/logout">{t('header.logout')}</Link>
      </S.Text>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { profileNavData } from '@app/constants/profileNavData';

import * as S from './ProfileNav.styles';

export const ProfileNav: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);

  const [userRole, setUserRole] = useState('normal');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.role) {
      if (profileNavData[user?.role]){
        setUserRole(user.role);
      } else {
        setUserRole('normal');
      }
    }
  }, [user]);

  return (
    <S.Wrapper>
      {profileNavData[userRole].map((item) => (
        <S.Btn
          key={item.id}
          icon={item.icon}
          type="text"
          color={item.color}
          onClick={() => navigate(item.href)}
          $isActive={`/profile/${item.href}` === location.pathname}
        >
          {t(item.name)}
        </S.Btn>
      ))}
    </S.Wrapper>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import * as SC from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { CheckCircleFilled } from '@ant-design/icons';
import { Avatar, Badge } from 'antd';
import { Card } from 'antd';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(90);
  const user = useAppSelector((state) => state.user.user);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
      {/* <Card bordered={false}>
      <Badge.Ribbon text={user?.status? user.status: "Not Verified"} color={user?.status? '#30AF5B': "#000"}>
      <S.ImgWrapper>
        <Avatar  shape="circle" src= {user?.avatar? user.avatar: "https://lightence-assets.s3.amazonaws.com/avatars/avatar1.webp"} alt="Profile" />        
      </S.ImgWrapper>
      </Badge.Ribbon>
      </Card> */}
      <S.Title>{`${profileData?.firstName} ${profileData?.lastName}`}</S.Title>
      <S.Subtitle>{profileData?.email}</S.Subtitle>
      {/* <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
      <S.Text>{t('profile.fullness')}</S.Text> */}
    </S.Wrapper>
  ) : null;
};

import { httpApi, authApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
import { CognitoIdentityProviderClient, InitiateAuthCommand, InitiateAuthCommandInput, ConfirmSignUpCommand, GetUserCommand, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import { jwtDecode } from 'jwt-decode';
import { config } from "../config";
import { encode } from 'querystring';
import axios, { AxiosRequestConfig } from "axios";
import { persistToken } from '@app/services/localStorage.service';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  email: string;
  code: string;
}

export interface NewPasswordData {
  email: string;
  securityCode: string;
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface  OAuth2Request {
  code: string;
}
export interface  OAuth2Response {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}
//
// export const login = (loginPayload: LoginRequest): Promise<LoginResponse> =>
//   authApi.post<LoginResponse>('/dev/user/login', { ...loginPayload }).then(({ data }) => data);
//
// export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
//   authApi.post<undefined>('/Prod/signup', { ...signUpData }).then(({ data }) => data);
//
// export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
//   authApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

// export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
//   authApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

// export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
//   authApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

const adminClient = new CognitoIdentityProviderClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: "",
    secretAccessKey: "",
  },
});

type ILoginReponse = {
  token: string;
  refreshToken: string;
  idToken: string;
  user: UserModel;
}

export const login = async (loginPayload: LoginRequest):Promise<ILoginReponse> => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: loginPayload.email,
      PASSWORD: loginPayload.password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    console.log(AuthenticationResult);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');


      const idToken = AuthenticationResult.IdToken || '';
      const accessToken = AuthenticationResult.AccessToken || '';
      const decodedToken = jwtDecode(idToken) as any;
      console.log('ID Token Decoded:', decodedToken);

      // Get user info
      const userInfoCommand = new GetUserCommand({
        AccessToken: accessToken,
      });

      const userInfoResponse = await cognitoClient.send(userInfoCommand);
      console.log("User attributes:", userInfoResponse.UserAttributes);

      // if (userInfoResponse && userInfoResponse.UserAttributes) {
      //   // Extract specific attributes
      //   const givenName = decodedToken.given_name || userInfoResponse.UserAttributes.find(attr => attr.Name === 'given_name')?.Value;
      //   const familyName = decodedToken.family_name || userInfoResponse.UserAttributes.find(attr => attr.Name === 'family_name')?.Value;
      //   const picture = decodedToken.picture || userInfoResponse.UserAttributes.find(attr => attr.Name === 'picture')?.Value;
      //
      //   console.log('Given Name:', givenName);
      //   console.log('Family Name:', familyName);
      //   console.log('Picture:', picture);
      // }
      let userProfile = null;
      let firstName = '';
      let lastName = '';
      let userRole = 'normal';
      if (decodedToken['given_name']) {
        firstName = decodedToken['given_name'];
        lastName = decodedToken['family_name'];
      }
      if (decodedToken['name']) {
        const fullName = decodedToken['name'].split(' ');
        firstName = fullName[0] || '';
        lastName = fullName[1] || '';
      }
      if (decodedToken['cognito:groups'] && decodedToken['cognito:groups'].length > 0) {
        userRole = decodedToken['cognito:groups'][0];
      }
      persistToken(idToken);
      try {
        const response = await httpApi.get('/v3/users', {
          params: {id: decodedToken['cognito:username']},
        });
        userProfile = response.data;
      } catch (e) {
        console.error('{!CRITICAL}: cannot read user info');
      }

      return {
        token: accessToken,
        refreshToken: AuthenticationResult.RefreshToken || "",
        idToken: idToken,
        user: {
          id: decodedToken['cognito:username'],
          groups: decodedToken['cognito:groups'],
          name: decodedToken['name'],
          firstName: firstName,
          lastName: lastName,
          cognito_id: decodedToken['cognito:username'],
          loginID: decodedToken['cognito:username'],
          email: decodedToken['email'],
          role: userRole,
          imgUrl: decodedToken['photo'],
          phone: decodedToken['phone'],
          exp: decodedToken['exp'],
          auth_time: decodedToken['auth_time'],
          profile: userProfile,
        },
      };
    } else {
      console.error("Error signing in: AuthenticationResult is empty");
      throw new Error("Error signing in: AuthenticationResult is empty");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const signUp = async (signUpData: SignUpRequest) => {
  return httpApi.post<undefined>('/v3/auth/signup', { ...signUpData }).then(({ data }) => data);
};

export const verifyOAuth2 = async (oAuth2Payload: OAuth2Request) => {
  try {
    const data = encode({
      grant_type: 'authorization_code',
      client_id: config.clientId,
      redirect_uri: config.redirectUri,
      ...oAuth2Payload
    });

    const httpConfig: AxiosRequestConfig = {
      method: 'post',
      url: "https://blinderai.auth.us-east-1.amazoncognito.com/oauth2/token",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: data
    };

    const result = await axios(httpConfig);
    console.log('---verifyOAuth2 result---', result);

    sessionStorage.setItem("idToken", result.data?.id_token || '');
    sessionStorage.setItem("accessToken", result.data?.access_token || '');
    sessionStorage.setItem("refreshToken", result.data?.refresh_token || '');


    const idToken = result.data?.id_token || '';
    const accessToken = result.data?.access_token || '';
    const decodedToken = jwtDecode(idToken) as any;
    console.log('ID Token Decoded:', decodedToken);

    let firstName = '';
    let lastName = '';
    if (decodedToken['given_name']) {
      firstName = decodedToken['given_name'];
      lastName = decodedToken['family_name'];
    }
    if (decodedToken['name']) {
      const fullName = decodedToken['name'].split(' ');
      firstName = fullName[0] || '';
      lastName = fullName[1] || '';
    }
    return {
      token: accessToken,
      refreshToken: result.data?.refresh_token,
      user: {
        id: decodedToken['cognito:username'],
        firstName: firstName,
        lastName: lastName,
        loginID: decodedToken['cognito:username'],
        email: decodedToken['email'],
        role: decodedToken['email'],
        imgUrl: decodedToken['photo'],
        phone: decodedToken['phone'],
        exp: decodedToken['exp'],
        auth_time: decodedToken['auth_time'],
      },
    };
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const verifyOAuth2Lambda = async (oAuth2Payload: OAuth2Request) => {
  try {
    const result = await authApi.post<OAuth2Response>('verify', { ...oAuth2Payload });
    console.log(result);
    sessionStorage.setItem("idToken", result.data?.idToken || '');
    sessionStorage.setItem("accessToken", result.data?.accessToken || '');
    sessionStorage.setItem("refreshToken", result.data?.refreshToken || '');


    const idToken = result.data?.idToken || '';
    const accessToken = result.data?.accessToken || '';
    const decodedToken = jwtDecode(idToken) as any;
    console.log('ID Token Decoded:', decodedToken);

    let firstName = '';
    let lastName = '';
    if (decodedToken['given_name']) {
      firstName = decodedToken['given_name'];
      lastName = decodedToken['family_name'];
    }
    if (decodedToken['name']) {
      const fullName = decodedToken['name'].split(' ');
      firstName = fullName[0] || '';
      lastName = fullName[1] || '';
    }
    return {
      token: accessToken,
      user: {
        id: decodedToken['cognito:username'],
        firstName: firstName,
        lastName: lastName,
        loginID: decodedToken['cognito:username'],
        email: decodedToken['email'],
        role: decodedToken['email'],
        imgUrl: decodedToken['photo'],
        phone: decodedToken['phone'],
      },
    };
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};


export const verifySecurityCode = async (securityCodePayload: SecurityCodePayload) => {
  const params = {
    ClientId: config.clientId,
    Username: securityCodePayload.email,
    ConfirmationCode: securityCodePayload.code,
  };
  try {
    const command = new ConfirmSignUpCommand(params);
    await cognitoClient.send(command);
    console.log("User confirmed successfully");
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};

// export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
//   authApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const refreshToken = async (rtoken: string) => {
  const params: InitiateAuthCommandInput = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      'REFRESH_TOKEN': rtoken,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    console.log(AuthenticationResult);
    if (AuthenticationResult) {
      sessionStorage.setItem("idToken", AuthenticationResult.IdToken || '');
      sessionStorage.setItem("accessToken", AuthenticationResult.AccessToken || '');
      sessionStorage.setItem("refreshToken", AuthenticationResult.RefreshToken || '');


      const idToken = AuthenticationResult.IdToken || '';
      const accessToken = AuthenticationResult.AccessToken || '';
      const decodedToken = jwtDecode(idToken) as any;
      console.log('ID Token Decoded:', decodedToken);

      // Get user info
      const userInfoCommand = new GetUserCommand({
        AccessToken: accessToken,
      });

      const userInfoResponse = await cognitoClient.send(userInfoCommand);
      console.log("User attributes:", userInfoResponse.UserAttributes);

      let firstName = '';
      let lastName = '';
      if (decodedToken['given_name']) {
        firstName = decodedToken['given_name'];
        lastName = decodedToken['family_name'];
      }
      if (decodedToken['name']) {
        const fullName = decodedToken['name'].split(' ');
        firstName = fullName[0] || '';
        lastName = fullName[1] || '';
      }
      return {
        token: accessToken,
        user: {
          id: decodedToken['cognito:username'],
          firstName: firstName,
          lastName: lastName,
          loginID: decodedToken['cognito:username'],
          email: decodedToken['email'],
          role: decodedToken['email'],
          imgUrl: decodedToken['photo'],
          phone: decodedToken['phone'],
          exp: decodedToken['exp'],
          auth_time: decodedToken['auth_time'],
        },
      };
    } else {
      console.error("Error signing in: AuthenticationResult is empty");
      throw new Error("Error signing in: AuthenticationResult is empty");
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const resetPassword = async (resetPasswordPayload: ResetPasswordRequest) => {
  const params = {
    ClientId: config.clientId,
    Username: resetPasswordPayload.email,
  };

  const command = new ForgotPasswordCommand(params);

  try {
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error reset password:", error);
    throw error;
  }
}

export const setNewPassword = async (newPasswordData: NewPasswordData) =>{
  const params = {
    ClientId: config.clientId,
    Username: newPasswordData.email,
    ConfirmationCode: newPasswordData.securityCode,
    Password: newPasswordData.newPassword,
  };

  const command = new ConfirmForgotPasswordCommand(params);

  try {
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Error confirming password reset:", error);
    throw error;
  }
}
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Typography  } from 'antd';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';

import {
  IResponseAuthInvite,
} from "@app/interfaces/interfaces";
import {
  verifyInvite,
  doAcceptInvitation,
} from "@app/api/blinder.user.api";
import { doLogin } from "@app/store/slices/authSlice";

import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from "@app/controllers/notificationController";
import { BaseButton, LinkButton } from "@app/components/common/BaseButton/BaseButton";

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
  termOfUse: boolean;
}

const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: false,
};

const AcceptInvitePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isTokenVerifing, setIsTokenVerifing] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      verifyInvite(token)
        .then((res) => {
          if (res === undefined) {
            notificationController.error({ message: 'Invalid Request.' });
            navigate('/auth/login');
            return;
          }

          if (res?.success === false) {
            notificationController.error({ message: res.message });
            navigate('/auth/login');
            return;
          }
          setIsTokenVerifing(false);
          initValues.email = res.email;
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          navigate('/auth/login');
        });
    } else {
      notificationController.error({ message: `Wrong callback URL.` });
      navigate('/auth/login');
    }
  }, [searchParams]);

  const handleSubmit = (values: SignUpFormData) => {
    if (values.termOfUse == false) {
      notificationController.error({ message: `You should agree Terms of Service and Privacy Policy of Blinderai.` });
      return;
    }
    setLoading(true);
    const token = searchParams.get("token") || '';
    doAcceptInvitation(
      values.firstName,
      values.lastName,
      values.email,
      values.password,
      values.role,
      token,
    ).then((res) => {
      if (!res) {
        setLoading(false);
        notificationController.error({
          message: 'Unknown Exception',
        });
        return;
      }
      if (!res?.success) {
        setLoading(false);
        notificationController.error({
          message: res?.message,
        });
        return;
      }
      notificationController.success({
        message: "Contragulation, You've registered your information successfully.",
      });
      dispatch(doLogin(values))
        .unwrap()
        .then((token) => {
          navigate('/home');
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <>      
      <PageTitle>Complete your info</PageTitle>
      {isTokenVerifing ? (
        <Auth.FormWrapper>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          <Typography.Title level={3}>Please wait for a while</Typography.Title>
        </Auth.FormWrapper>
      ): (
      <Auth.FormWrapper>
        <Typography.Title level={3}>Complete your account info</Typography.Title>
        <BaseForm layout="vertical"
         onFinish={handleSubmit}
         requiredMark="optional"
         initialValues={initValues}
        >
          <Auth.FormItem
            name="email"
            label={t('common.email')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput placeholder={t('common.email')} readOnly />
          </Auth.FormItem>

          <Auth.FormItem
            name="firstName"
            label={t('common.firstName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.firstName')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="lastName"
            label={t('common.lastName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.lastName')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInputPassword placeholder={t('common.password')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.confirmPassword')}
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: t('common.requiredField') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common.confirmPasswordError')));
                },
              }),
            ]}
          >
            <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
              <Auth.FormCheckbox>
                <Auth.Text>
                  {t('signup.agree')}{' '}
                  <LinkButton href="/terms_conditions" target={'_blank'}>
                    {t('blinder.text.termsOfService')}
                  </LinkButton>{' '}
                  and{' '}
                  <LinkButton href="https://clerkbridge.com/privacy-policy/" target={'_blank'}>
                    {t('blinder.text.privacyPolicy')}
                  </LinkButton>
                </Auth.Text>
              </Auth.FormCheckbox>
            </BaseForm.Item>
          </Auth.ActionsWrapper>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              Update
            </Auth.SubmitButton>
          </BaseForm.Item>
        </BaseForm>
      </Auth.FormWrapper>
      )}
    </>
  );
};

export default AcceptInvitePage;



export const config= {
  "region": "us-east-1",
  "userPoolId": "us-east-1_PTONG6a7j", // "us-east-1_N1BwpD8My",
  "clientId": "57b3k7mujn7rd2i537n4rqmmld", // "7bga6l8k7eri6se62eni3gjc08"
  "cognitoDomain": "blinderai.auth.us-east-1.amazoncognito.com",
  "redirectUri": process.env.NODE_ENV === 'development'?'http://localhost:3000/auth/callback':"https://copyright.blinderai.com/auth/callback",

  // -- mine's sandbox
  // "SQUAREUP_APPLICATION_ID": 'sandbox-sq0idb-jAkoK78x-mLJIlDc8KYgyg',
  // "SQUAREUP_LOCATION_ID": 'LQ2PBK3WJQH0P',
  // -- customer's sandbox
  // "SQUAREUP_APPLICATION_ID": 'sandbox-sq0idb-ysVAyspvj6yMgCgjNdLGww',
  // "SQUAREUP_LOCATION_ID": 'LY3PZT5E748PF',

  // - sqareup live
  "SQUAREUP_APPLICATION_ID": 'sq0idp-Uwtdqyt_gt6Duho98CsmNg',
  "SQUAREUP_LOCATION_ID": 'LQV88ER6VWE6W',
}
import styled, { css } from 'styled-components';
import { BurgerIcon } from '@app/components/common/Burger/BurgerIcon';
import { GitHubButton } from '@app/components/header/components/GithubButton/GitHubButton';
import { LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCollapse } from '../common/BaseCollapse/BaseCollapse';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseImage } from "@app/components/common/BaseImage/BaseImage";

export const HeaderActionWrapper = styled.div`
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.25rem;

    @media only screen and ${media.md} {
      font-size: 1.625rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
`;

export const DropdownCollapse = styled(BaseCollapse)`
  & > .ant-collapse-item > .ant-collapse-header {
    font-weight: 600;
    font-size: 0.875rem;

    color: var(--primary-color);

    @media only screen and ${media.md} {
      font-size: 1rem;
    }
  }

  & > .ant-collapse-item-disabled > .ant-collapse-header {
    cursor: default;

    & > span[role='img'] {
      display: none;
    }
  }
`;

export const BurgerCol = styled(BaseCol)`
  z-index: 999;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;
//
// export const SearchColumn = styled(BaseCol)`
//   padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
// `;

export const SearchColumn = styled(BaseCol)`
  padding: 0;
`;

interface ProfileColumn {
  $isTwoColumnsLayout: boolean;
}

// export const ProfileColumn = styled(BaseCol)<ProfileColumn>`
//   @media only screen and ${media.md} {
//     ${(props) =>
//       props?.$isTwoColumnsLayout &&
//       css`
//         background-color: var(--sider-background-color);
//         padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
//       `}
//   }
// `;

export const ProfileColumn = styled(BaseCol)<ProfileColumn>`
  flex: 0 0 170px;
  width: 170px;
`;

export const GHButton = styled(GitHubButton)`
  display: none;

  @media only screen and ${media.lg} {
    display: block;
  }
`;

export const Image = styled(BaseImage)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const LogoColumn = styled(BaseCol)`
  flex: 0 0 200px;
  width: 200px;
  @media only screen and ${media.xl} {
    flex: 0 0 350px;
    width: 350px;
  }
`;

export const MenuColumn = styled(BaseCol)`
  flex: 1;
`